module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/builds/playmoweb/blog.playmoweb.com/node_modules/gatsby-remark-images","id":"02d71a44-ee01-54d6-af4e-ef78a035c9df","name":"gatsby-remark-images","version":"3.8.1","pluginOptions":{"plugins":[],"maxWidth":700,"backgroundColor":"transparent","showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":700,"backgroundColor":"transparent","showCaptions":true}},"gatsby-remark-copy-linked-files","gatsby-remark-embed-video",{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},"gatsby-remark-autolink-headers","gatsby-remark-smartypants",{"resolve":"@weknow/gatsby-remark-twitter","options":{"align":"center"}},"gatsby-remark-external-links"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/builds/playmoweb/blog.playmoweb.com"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700,"backgroundColor":"transparent","showCaptions":true,"linkImagesToOriginal":true,"markdownCaptions":false,"sizeByPixelDensity":false,"quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-38166641-3","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-L1YSGDQTG2"],"pluginConfig":{"head":true,"respectDNT":true,"exclude":["/preview/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Playmoweb","short_name":"Playmoweb","start_url":"/","background_color":"#001724","theme_color":"#001724","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e3b316345df59c412a30b175469f6553"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
